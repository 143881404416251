import OSS from "ali-oss";

//神光少年OSS配置参数
const OSSConfig = {
  // uploadHost: 'http://xxx-oss-cn-beijing.aliyuncs.com',
  ossParams: {
    success_action_status: '200',
    region: "oss-cn-beijing",
    accessKeyId: "LTAIuIJwjQDNloUw",
    accessKeySecret: "GohgvAzqsYA7pTWeceXcftLVy9TMPH",
    bucket: "bwdev"
  },
}
//北维OSS配置参数
// const OSSConfig = {
//   uploadHost: 'http://xxx-oss-cn-beijing.aliyuncs.com',
//   ossParams: {
//     region: 'oss-cn-beijing',
//     success_action_status: '200',
//     accessKeyId: 'LTAI5tPvZsRKoHMzucrP3dqf',
//     accessKeySecret: 'pnzYFuQnvHdhN94tlkALq50ZTpSVpW',
//     bucket: 'yg-bucket001',
//   },
// }
function getFilename(file) {
  let date = new Date();
  let yaer = date.getFullYear().toString();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  let hour = date.getDate().toString();
  let min = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();
  let filename = ""
  let tmp = filename + yaer + month + day + hour + min + seconds + file.name;
  return tmp
}

function uploadOSS(file) {
  return new Promise(async (resolve, reject) => {
    const fileName = getFilename(file)
    let client = new OSS({
      region: OSSConfig.ossParams.region,
      accessKeyId: OSSConfig.ossParams.accessKeyId,
      accessKeySecret: OSSConfig.ossParams.accessKeySecret,
      bucket: OSSConfig.ossParams.bucket,
    })
    // const res = await client.multipartUpload(fileName, file)
    const res = await client.put(fileName, file)
    resolve(res)
  })
}

export { uploadOSS }