<template>
    <div class="main">
        <div class="box1">
            <div class="top">
                <div class="top2">
                    <h3 style="padding: 20px;">{{ res.cname }}</h3>
                    <!-- <span>门店上级：{{ res.pname }}</span> -->
                </div>
                <div>
                    <el-button icon="el-icon-edit" type="primary" style="margin-right: 10px;"
                        @click="edit()">编辑</el-button>
                    <el-button icon="el-icon-check" type="primary" style="margin-right: 10px;" @click="dele()"
                        v-if="zhuangtai == 20">打开</el-button>
                    <el-button v-if="zhuangtai == 10" icon="el-icon-close" type="primary" style="margin-right: 10px;"
                        @click="dele()">关闭</el-button>
                </div>
            </div>
            <div class="han">
                <div class="han2">
                    <span style="margin-top: 10px;">渠道级别：{{ res .mlvl }}</span>
                    <span style="margin-top: 10px;">渠道类型：{{ res .mtype }}</span>
                    <span style="margin-top: 10px;">渠道简称：{{ res.jname }}</span>
                    <span style="margin-top: 10px;">客服微信：{{ res.wxkf }}</span>
                    <span style="margin-top: 10px;">登录账号：{{ res.loginname }}</span>
                    <span style="margin-top: 10px; margin-bottom: 20px;     word-break: break-all;">门店简介：{{
                        res.content
                    }} </span>
                </div>
                <div class="hang2">
                    <span style="margin-top: 10px;">管理员：{{ res.gname }}</span>
                    <span style="margin-top: 10px;">服务商代码：{{ code1 }}</span>
                    <span style="margin-top: 10px;">登录次数：{{ res.usenum }}</span>
                    <span style="margin-top: 10px;">联系电话：{{ res.phone }}</span>
                    <span style="margin-top: 10px;">详细地址：{{ res.address }}
                    </span>
                </div>

            </div>
        </div>

        <div class="box1">
            <div class="han">
                <div class="top2">
                    <h3 style="padding: 20px;">用户数</h3>
                </div>
            </div>
            <div class="han3">
                <div class="mian2">
                    <span class="text3">全部</span>
                    <span class="text3">{{ sumData.nums }}</span>
                </div>
                <div class="mian2">
                    <span class="text3">未成年人</span>
                    <span class="text3">{{ sumData.num1 }}</span>
                </div>
                <div class="mian2">
                    <span class="text3">档案数</span>
                    <span class="text3">{{ sumData.num2 }}</span>
                </div>
                <div class="mian2">
                    <span class="text3">月度新增</span>
                    <span class="text3">{{ sumData.num3 }}</span>
                </div>

            </div>
        </div>

        <div class="box1">
            <div class="han">
                <div class="han4">
                    <h3 style="padding: 20px;margin-left: -20px;">专属渠道地址</h3>
                    <span style="margin-bottom: 20px;">{{ res.url + res.code }}</span>
                </div>
                <!-- <div class="han2">
                    <h3 style="padding: 20px;margin-left: -20px;">专属检查入口</h3>
                    <span style="margin-bottom: 20px;">http：www.shiidangan.com/8499/48.html</span>
                </div> -->
            </div>
        </div>
        <div class="box1">
            <div class="han2">
                <h3 style="    padding: 20px; margin-left: -20px;">门店二维码</h3> <el-image
                    style="width: 200px; height: 200px ;margin-bottom: 20px;" :src="url" fit="scale-down"></el-image>
            </div>
        </div>
        <el-dialog title="编辑渠道" :visible.sync="show" width=35% center>
            <el-form label-width="80px" :model="ruleForm" label-position="right" :rules='rules'>
                <div class="from">
                    <div class="from2">
                        <div>
                            <el-form-item label="渠道名称" prop="cname">
                                <el-input v-model="ruleForm.cname"></el-input>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item label="渠道简称" prop="jname">
                                <el-input v-model="ruleForm.jname"></el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <div class=from2>
                        <el-form-item label="城市:" prop="address1">
                            <div style=";display:flex;justify-content: space-between;">
                                <el-cascader v-model='address11' :options="regionData" placeholder="选择城市"
                                    :props="{ expandTrigger: 'hover' }" @change="handleChange" clearable>
                                </el-cascader>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="from2">
                        <el-form-item label="客服微信" prop="wxkf">
                            <el-input v-model="ruleForm.wxkf"></el-input>
                        </el-form-item>
                        <el-form-item label="服务商代码" label-width="100px" prop="code1">
                            <el-input v-model="ruleForm.code1"></el-input>
                        </el-form-item>
                    </div>
                    <div class="from2">
                        <el-form-item label="管理员" prop="gname">
                            <el-input v-model="ruleForm.gname"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                    </div>
                    <div class="from2">
                        <el-form-item label="渠道性质" prop="mtype">
                            <el-select v-model="ruleForm.mtype" placeholder="请选择" clearable>
                                <el-option v-for="item in storeNature" :key="item.value" :label="item.v"
                                    :value="item.k">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="渠道级别" prop="mlvl">
                            <el-select v-model="ruleForm.mlvl" placeholder="请选择" clearable>
                                <el-option v-for="item in storeLevel" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="from3">
                        <el-form-item label="登录账号" prop="loginname">
                            <el-input v-model="ruleForm.loginname" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="登录密码" prop="pwd">
                            <el-input v-model="ruleForm.pwd"></el-input>
                        </el-form-item>
                        <el-form-item label="输入授权码" label-width="90px" prop="sqcode">
                            <el-input v-model="ruleForm.sqcode"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="门店简介" prop="content">
                            <el-input type="textarea" v-model="ruleForm.content" maxlength="1000" :rows="5"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="详细地址" prop="address">
                            <el-input v-model="ruleForm.address"></el-input>
                        </el-form-item>

                    </div>
                    <div>
                        <el-form-item label="公众号二维码" prop="qrcode" label-width="100px">
                            <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                                :on-preview="handlePreview" :on-remove="handleRemove"
                                :before-upload="beforeAvatarUpload" :http-request="uploadFile" :file-list="fileList"
                                :limit="1" list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过1MB</div>
                            </el-upload>
                        </el-form-item>
                    </div>
                </div>
                <p class="btns">
                    <el-button :size="tabsize" @click="show = false">取消</el-button>
                    <el-button :size="tabsize" type="primary" @click="onSubmit('ruleForm', ruleForm)">提交</el-button>
                </p>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { uploadOSS } from '../../../requset/apis/alioss'
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
    computed: {
        tabsize() {
            // this.$setTableHeight();
            return this.$store.state.tabsize;
        },
    },
    data() {
        return {
            code1: '',
            url: "",
            show: false,
            value: '',
            ruleForm: {
                cname: '',//渠道名称
                img: '',//照片url
                jname: '',
                wxkf: '',
                add1: '',
                add2: '',
                add3: '',
                address: '',
                gname: '',
                phone: '',
                mtype: '',
                mlvl: '',
                content: '',
                loginname: '',
                pwd: '',
                qrcode: '',
                code1: '',
                sqcode: '',
                add: "",
                pid: "",//上级渠道id
                id: "",
                dizhi: '',
                status: ''
            },
            rules: {
                cname: [{
                    required: true,
                    message: '请输入渠道名称',
                    trigger: 'blur'
                }],
                jname: [{
                    required: true,
                    message: '请输入渠道简称',
                    trigger: 'blur'
                }],
                wxkf: [{
                    required: true,
                    message: '请输入客服微信',
                    trigger: 'blur'
                }],
                gname: [{
                    required: true,
                    message: '请输入管理员',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    pattern: /^1[3456789]\d{9}$/,
                    message: '请输入正确的联系电话',
                    trigger: 'blur'
                }],
                mtype: [{
                    required: true,
                    message: '请选择性质',
                    trigger: 'change'
                }],
                mlvl: [{
                    required: true,
                    message: '请选择渠道级别',
                    trigger: 'change'
                }],
                loginname: [{
                    required: true,
                    message: '请输入登录账号',
                    trigger: 'blur'
                }],
                pwd: [{
                    required: true,
                    message: '请输入登录密码',
                    trigger: 'blur'
                }],
                address1: [{
                    required: true,
                    message: '请选择城市',
                    trigger: 'change'
                }],
                address: [{
                    required: true,
                    message: '请输入具体位置',
                    trigger: 'blur'
                }],
                code1: [{
                    required: true,
                    // min: 6, max: 6,
                    message: '请输入服务商码（6位）',
                    pattern: /^[a-z0-9]{6}$/,
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请输入简介',
                    trigger: 'blur'
                }],
                qrcode: [{
                    required: true,
                    message: '请上传图片',
                    trigger: 'change'
                }],
                sqcode: [{
                    required: true,
                    message: '请输入授权码',
                    trigger: 'blur'
                }]
            },//表单验证
            storeLevel: [
                {
                    value: 1,
                    label: 'A'
                }, {
                    value: 2,
                    label: 'B'
                }, {
                    value: 3,
                    label: 'C'
                }, {
                    value: 4,
                    label: 'D'
                }, {
                    value: 5,
                    label: 'E'
                },
            ],
            sort: "id",
            order: "desc",
            currentPage: 1, //默认显示第一页
            pageSize: 10, //默认每页显示10条
            totalNum: "", //总页数
            tableData: [],
            fileList: [{ url: "" }],
            id: '',
            res: '',
            sumData: '',
            status: '',
            storeNature: [],
            regionData: [],
            address11: {},
            address1: [],
            zhuangtai: ''


        };
    },
    methods: {
        handleChange(value) {
            // this.ruleForm.add = value//表单验证
            this.ruleForm.add1 = value[0]
            this.ruleForm.add2 = value[1]
            this.ruleForm.add3 = value[2]
            // console.log(this.address11);
        },
        edit() {
            this.show = true
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 1MB!');
            }
            return isLt2M;
        },

        uploadFile(item) {
            console.log("上传图片", item.file);
            uploadOSS(item.file).then((res) => {
                console.log("地址", res);
                this.ruleForm.img = res.url
            });
        },

        list() {
            this.$api.channel.getById({
                id: this.id
            }).then((res) => {
                console.log(res);
                this.res = res.obj
                this.url = res.obj.qrcode
                this.ruleForm = res.obj
                this.fileList[0].url = res.obj.qrcode
                this.code1 = res.obj.code1
                console.log(this.code1);
                this.ruleForm.code1 = res.obj.code1.substring(0, 6);
                console.log(typeof this.ruleForm.code1);
                this.address1 = JSON.stringify(this.ruleForm)
                this.address1 = JSON.parse(this.address1)
                this.ruleForm.address1 = [],
                this.ruleForm.address1.push(JSON.parse(JSON.stringify(this.address1.add1)))
                this.ruleForm.address1.push(JSON.parse(JSON.stringify(this.address1.add2)))
                this.ruleForm.address1.push(JSON.parse(JSON.stringify(this.address1.add3)))
                this.address11 = JSON.parse(JSON.stringify(this.ruleForm.address1))
                // console.log("111", this.address11);
                this.zhuangtai = res.obj.status
                this.$api.channel.getinfo({
                    dtype: 'qd_type'
                }).then((res) => {
                    this.storeNature = res.obj
                    for (var i = 0; i < this.storeNature.length; i++) {
                        if (this.ruleForm.mtype == this.storeNature[i].k) {
                            this.ruleForm.mtype = this.storeNature[i].v
                        }
                    }
                })
                for (var j = 0; j < this.storeLevel.length; j++) {
                    if (this.ruleForm.mlvl == this.storeLevel[j].value) {
                        this.ruleForm.mlvl = this.storeLevel[j].label
                    }
                }

            })
        },
        numlist() {
            this.$api.channel.userReport({
                id: this.id
            }).then((res) => {
                console.log(res);
                this.sumData = res.obj
            })
        },
        dele() {
            if (this.zhuangtai == 10) {
                this.zhuangtai = 20
            } else if (this.zhuangtai == 20) {
                this.zhuangtai = 10
            }
            this.$api.channel.upStatus({
                id: this.id,
                status: this.zhuangtai
            }).then((res) => {
                console.log(res);
                if (res.msg == "操作成功") {
                    this.$notify({
                        title: '成功',
                        message: res.msg,
                        type: 'success'
                    });
                    // this.list()
                } else {
                    this.$notify({
                        title: '错误',
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        status2() {
            // this.status = this.$route.query.status
            if (this.$route.query.status == "正常") {
                this.status = 10
            } else if (this.$route.query.status == "关闭") {
                this.status = 20
            } else {
                this.status = 0
            }
        },
        getinfo() {//获取字典
            this.$api.channel.getinfo({
                dtype: 'qd_type'
            }).then((res) => {
                console.log('字典', res);
                this.storeNature = res.obj

            })
        },
        onSubmit(ruleForm) {//from提交

            for (var i = 0; i < this.storeNature.length; i++) {
                if (this.ruleForm.mtype == this.storeNature[i].v) {
                    this.ruleForm.mtype = this.storeNature[i].k
                }
            }

            for (var j = 0; j < this.storeLevel.length; j++) {
                if (this.ruleForm.mlvl == this.storeLevel[j].label) {
                    this.ruleForm.mlvl = this.storeLevel[j].value
                }
            }

            this.$api.channel.update({
                cname: this.ruleForm.cname,//渠道名称
                jname: this.ruleForm.jname,
                wxkf: this.ruleForm.wxkf,
                add1: this.ruleForm.add1,
                add2: this.ruleForm.add2,
                add3: this.ruleForm.add3,
                address: this.ruleForm.address,
                gname: this.ruleForm.gname,
                phone: this.ruleForm.phone,
                mtype: this.ruleForm.mtype,
                mlvl: this.ruleForm.mlvl,
                content: this.ruleForm.content,
                loginname: this.ruleForm.loginname,
                pwd: this.ruleForm.pwd,
                qrcode: this.ruleForm.qrcode,
                code1: this.ruleForm.code1,
                pid: this.ruleForm.pid,
                id: this.ruleForm.id,
                sqcode: this.ruleForm.sqcode,

            }).then((res) => {
                console.log(res);
                if (res.status == 1) {
                    this.$notify({
                        title: '成功',
                        message: res.msg,
                        type: 'success'
                    });
                    this.show = !this.show

                } else {
                    this.$notify({
                        title: '错误',
                        message: res.msg,
                        type: 'error'
                    });
                    this.show = this.show

                }
                this.list()
            })

        },
    },

    created() {
        this.id = this.$route.query.id
        this.status2();
        // console.log(this.id);
        this.list();
        this.numlist();
        this.getinfo();
        this.regionData = regionData;

    },
    mounted() {

    }
};
</script>
<style  scoped>
.main {
    position: relative;
    width: 98%;
    height: 98%;
    margin: 1%;
}

.box1 {
    background-color: white;
    width: 100%;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.top2 {
    display: flex;
    align-items: center;
}

.han {
    width: 80%;
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;
}

.han2 {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 80%;
}

.hang2 {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 100%;

}

.han3 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.han4 {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.mian2 {
    /* border-block: revert;
    width: 15vh;
    height: 8vh;
    border-style: hidden solid solid hidden;
    box-shadow: 8px 8px 10px 4px #aaa;
    border-width: 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 20px; */
    background-color: #409eff;
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 60px;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin-bottom: 20px;
}

.text3 {
    font-size: 15px;
}

.from {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.from2 {
    display: flex;
    justify-content: space-between;
}

.from3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* /deep/.el-form-item__content {
    display: flex;
}

/deep/.el-input--suffix .el-input__inner {
    padding-right: 14px;
} */
/deep/.el-input--suffix .el-input__inner {
    padding-right: 14px;
}

/deep/.el-form-item__content {
    display: flex;
}

/deep/.el-form-item__label {
    width: 106px ! important
}
</style>